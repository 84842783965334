@mixin maas-table-expanding {
  .p-table--expanding {
    .p-table__expanding-panel {
      @extend %vf-card;
      background-color: $color-x-light;
      margin-bottom: 0;
      margin-left: 0 !important; //XXX: this should be in vanilla. we need to remove the margin:0 from all tds as that has sideeffects on nested tables
      padding: $spv--small; //XXX: card padding shouldn't be scaleable. can be removed once https://github.com/vanilla-framework/vanilla-framework/pull/1920 is merged
      width: 100%; // overrides mobile card-table width
      white-space: wrap; // inherits no-wrap from td, whic leads to overflows
      &.is-active {
        flex-grow: 1;
      }
      &--bordered {
        @extend .p-table__expanding-panel;
        border-top: 1px solid $color-mid-light;
      }
    }

    td {
      display: table-cell;
      &[colspan="2"] {
        flex: 2;
      }
    }

    tr {
      display: table-row; //XXX (2) ove flex to only direct descendants as it is breaking nexsted tables
      .is-active {
        background-color: $color-x-light;
      }
    }
  }

  .p-table--expanding > thead > tr,
  .p-table--expanding > tbody > tr {
    display: flex; //XXX (1): move flex to only direct descendants as it is breaking nexsted tables
  }

  // Remove margins from tag inputs within tables
  .p-table--expanding .tags-input .tags .input {
    margin-bottom: 0;
  }
}
