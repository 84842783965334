@mixin maas-b-forms {
  maas-obj-field {
    margin-bottom: 0 !important;
    padding: 0 !important;
  }

  %u-input-min-margin--bottom {
    margin-bottom: $spv-nudge-compensation * 2 !important;
  }

  // XXX: Lyubo: override vertically-condensed an vertically narrow styling which is meant to happen only in narrow tds, not expanded ones.
  // Can be removed once this is merged: https://github.com/vanilla-framework/vanilla-framework/pull/1920/commits/c48e325a842f5f73fc70dd1b727f70a68836fd28
  [type="text"],
  [type="date"],
  [type="datetime"],
  [type="datatime-local"],
  [type="month"],
  [type="time"],
  [type="week"],
  [type="color"],
  [type="number"],
  [type="search"],
  [type="password"],
  [type="email"],
  [type="url"],
  [type="tel"],
  textarea,
  select {
    table & {
      margin-bottom: $spv--x-large + $sp-unit - $spv-nudge * 2;
      padding-bottom: calc(#{$spv-nudge} - 1px);
      padding-top: calc(#{$spv-nudge} - 1px);
      min-height: 2.3rem;
      min-width: auto;
    }

    .is-small & {
      margin-bottom: $spv-nudge-compensation;
      padding-bottom: calc(#{$spv-nudge - $sp-unit * 0.5} - 1px);
      padding-top: calc(#{$spv-nudge - $sp-unit * 0.5} - 1px);
    }
  }

  // fix checkbox so it doesn't get misaligned when next to a label with a different padding-top than the default label
  input[type="checkbox"] {
    .p-inline-list__item & + label {
      &::before {
        top: 0.5rem;
      }

      &::after {
        top: 11px;
      }
    }
  }

  .obj-saving {
    margin-right: 0.5rem;
  }
}

$box-offset-top--smallest-text: -0.15rem;

%checkbox--smallest-text {
  &::before {
    top: $box-offset-top--smallest-text;
  }

  &::after {
    top: #{$box-offset-top--smallest-text + 0.1875rem};
  }
}

table th input[type="checkbox"] + label {
  @extend %checkbox--smallest-text;
}
