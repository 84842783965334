@mixin maas-table-dhcp-snippets {
  .p-table--dhcp-snippets {
    th:nth-child(1),
    td:nth-child(1) {
      width: 15%;
    }

    th:nth-child(2),
    td:nth-child(2) {
      width: 10%;
    }

    th:nth-child(3),
    td:nth-child(3) {
      width: 20%;
    }

    th:nth-child(4),
    td:nth-child(4) {
      width: 10%;
    }

    th:nth-child(5),
    td:nth-child(5) {
      width: 35%;
    }

    th:nth-child(6),
    td:nth-child(6) {
      width: 10%;
    }

    .p-table__expanding-panel {
      width: 100%;
    }
  }
}
