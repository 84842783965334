@mixin maas {
  body {
    background-color: $color-light;
  }

  maas-obj-field,
  maas-obj-errors,
  maas-obj-form,
  storage-disks-partitions,
  storage-filesystems {
    display: block;
    position: relative;
  }

  maas-obj-field[type="text"],
  maas-obj-field[type="password"] {
    background-color: transparent;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    padding: 0;
  }

  textarea {
    min-height: 175px;
  }

  .p-table--mobile-card {
    td[aria-label] {
      min-height: 2rem;
    }

    td,
    tr {
      @media (max-width: $breakpoint-small) {
        overflow-x: visible;
      }
    }
  }

  p:empty,
  ul:empty,
  label:empty {
    margin: 0;
    padding: 0;
  }

  .tags .input {
    width: 100% !important;
  }

  dl dt:first-of-type {
    margin-top: 0;
    padding-top: 0;
  }

  .u-remove-max-width {
    max-width: none;
  }

  .p-form__control {
    margin-top: 0;
    white-space: normal;
  }

  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    padding-right: 2.3rem;
  }

  maas-obj-field[type="password"] {
    background: transparent;
    border: 0;
  }

  .p-list-tree .p-list-tree::after {
    display: none !important;
  }

  .editable {
    padding: 0.5rem 1rem;
    border: 1px solid transparent;

    &:hover,
    &.editmode {
      border: 1px solid $color-mid-light;
    }
  }

  .page-header__title-domain {
    display: inline-block;
    width: auto;
    @include vf-heading-4;
  }

  .col-12 {
    width: 100%;
  }

  // Checkbox height is not needed
  .p-form__control [type="checkbox"] {
    height: auto;
    min-height: auto;
  }

  // The contextual menu action dropdown list should not be smaller than the
  // button
  .p-contextual-menu__dropdown {
    display: block;
    min-width: 13rem; // Enough to fit "Override faild texting without truncation. AMend if there's a longer one."
    width: 100%;
  }

  .p-tooltip__message {
    margin: 0;
    z-index: 100;
  }

  .u-wrap {
    white-space: normal;
  }
}

// XXX Prevent "create new tag" showing on certain autocomplete modules
// Issue: https://bugs.launchpad.net/maas/+bug/1823014
.hide-create-tag-label .create-tag-label {
  display: none;
}

// Apply text overflow ellipsis to any element
.u-text-overflow {
  cursor: text;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Default styles for maas elements e.g. <maas-obj...
%maas-element {
  display: block;
  position: relative;
  width: 100%;
}

// DHCP snippets table
maas-dhcp-snippets-table {
  @extend %maas-element;
}

.dhcp-checkbox {
  .p-form__label {
    float: left;
    margin-right: 1rem;
  }

  .dhcp-checkbox__label {
    &::before {
      top: 0.5rem;
    }

    &::after {
      top: 0.75rem;
    }
  }
}

.page-header__message {
  margin-bottom: 1rem;
}

.p-warning-message {
  position: relative;
  padding-left: 1.5rem;
}

.p-warning-message__icon {
  position: absolute;
  top: 0.2rem;
  left: 0;
}
