@mixin maas-p-notifications {
  @include maas-notification;
  @include maas-notification-group;
  @include maas-notification-subtle;
}

@mixin maas-notification-group {
  .p-notification--group {
    > [class^="p-notification"] {
      flex-direction: column;
    }

    .p-list--divided {
      margin-top: $sp-small;
      border-top: 1px dotted $color-mid-light;
    }
  }
}

@mixin maas-notification {
  .p-notification {
    &__response {
      max-width: none;
    }
  }

  .p-notification--has-date,
  .p-notification--has-action {
    display: flex;

    .p-notification__response {
      display: flex;
      flex: 1;
    }

    .p-notification__message {
      flex: 1;
    }

    .p-notification__date,
    .p-notification__action {
      flex: 0;
      white-space: nowrap;
      margin-left: $spv--small;
    }
  }
}

@mixin maas-notification-subtle {
  [class*="p-notification"].is-subtle {
    background: transparent;
    box-shadow: none;

    &::before {
      height: 0;
    }
  }
}
