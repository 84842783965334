@mixin maas-inline-lists {
  //Fix for action buttons in headers
  .p-inline-list__item div {
    display: inline-block;
  }

  .p-inline-list__item--compact {
    @include vf-inline-list-item;
    margin-right: $sph--small;
  }
}
