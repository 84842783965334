@import "tables/base_tables";
@import "tables/breakpoint-widths";
@import "tables/patterns_double-row";
@import "tables/patterns_table-controllers";
@import "tables/patterns_table-expanding";
@import "tables/patterns_table-storage";
@import "tables/patterns_table-testing";
@import "tables/patterns_table-dhcp-snippets";

@include maas-b-tables;
@include maas-double-row;
@include maas-table-controllers;
@include maas-table-expanding;
@include maas-table-storage;
@include maas-table-testing;
@include maas-table-dhcp-snippets;

// Global MAAS table classes
.p-muted-text {
  @extend %small-text;
  color: $color-mid-dark;
  margin: 0;
  padding: 0;
}

.p-link--muted {
  &:visited {
    color: $color-mid-dark;
  }

  &:hover {
    color: $color-link;
  }
}

.p-table__row--muted {
  background-color: $color-light;
}
