@mixin maas-double-row {
  $checkbox-space: 1rem + $sph--large;
  $icon-space: map-get($icon-sizes, default) + $sph--small;

  .p-double-row {
    display: block;
    overflow: visible;

    %p-double-row__icon-container {
      display: block;
      float: left;
      width: map-get($icon-sizes, default);
    }

    %p-double-row__row {
      align-items: center;
      display: flex;
      white-space: nowrap;
      width: 100%;
    }

    .p-double-row__checkbox {
      @extend %p-double-row__icon-container;
      margin-right: $sph--large;
    }

    .p-double-row__icon-container {
      @extend %p-double-row__icon-container;
      margin-right: $sph--small;
    }

    .p-double-row__rows-container--icon {
      float: left;
      width: calc(100% - #{$icon-space});
    }

    .p-double-row__rows-container--checkbox {
      float: left;
      width: calc(100% - #{$checkbox-space});
    }

    .p-double-row__main-row {
      @extend %p-double-row__row;

      &.u-truncate {
        // Allow the content to be truncated.
        display: block;
      }
    }

    .p-double-row__muted-row {
      @extend %small-text;
      @extend %p-double-row__row;
      color: $color-mid-dark;
      margin-bottom: map-get($line-heights, default-text) -
        map-get($line-heights, small) - map-get($nudges, small);
    }
  }
}
