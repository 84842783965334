@mixin maas-cards {
  .p-card--highlighted {
    &.is-error {
      border-top: 3px solid $color-negative;
    }
  }

  //XXX: card padding shouldn't be scaleable. can be removed once https://github.com/vanilla-framework/vanilla-framework/pull/1920 is merged
  div[class*="p-card--"],
  .p-card {
    padding: $spv--large;
  }

  //XXX 13.08.2018 Lyubomir Popov: Override negative margin on p-card__content, which only works when the heading is an h2, as in the vanilla example; Remove once this has been resolved in vanilla
  .p-card__content {
    margin-top: 0;

    h2 + &,
    .p-heading--2 + & {
      margin-top: -$sp-unit * 2;
    }

    .muted-label {
      color: $color-mid-dark;
    }
  }
}
